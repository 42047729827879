import React from "react"

import Layout from "../components/layout"
import PortfolioPage from "../components/portfolio/index"
import Seo from "../components/seo"

const portfolioPage = () => (
  <Layout>
    <Seo title="Portfolio - Mass Media Creatives" desc="" keywords="" />
    <PortfolioPage />
  </Layout>
)

export default portfolioPage
