import React from "react"
import Slider from "./slider"
import About from "./about"
import Services from "../home/services"
import Showcase from "../home/showcase"

const portfolioPage = () => {
  return (
    <>
      <Slider />
      <About />
      <Showcase />
      <Services />
    </>
  )
}

export default portfolioPage
